@use 'abstracts';

html,
body {
  background: var(--color-primary13);
}

.highlighted-text {
  background-color: rgba(255, 159, 0, 0.21);
}
