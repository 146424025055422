@font-face {
  font-family: 'iris-icons';
  src: url('fonts/iris-icons.eot?5pyklb');
  src: url('fonts/iris-icons.eot?5pyklb#iefix') format('embedded-opentype'), url('fonts/iris-icons.ttf?5pyklb') format('truetype'),
    url('fonts/iris-icons.woff?5pyklb') format('woff'), url('fonts/iris-icons.svg?5pyklb#iris-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iris-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-nota-sin-leer:before {
  content: '\e946';
}
.icon-idiomas1:before {
  content: '\e972';
  color: #0082ff;
}
.icon-imagotipo-iris .path1:before {
  content: '\e950';
  color: rgb(71, 215, 172);
}
.icon-imagotipo-iris .path2:before {
  content: '\e951';
  margin-left: -1em;
  color: rgb(9, 110, 220);
}
.icon-imagotipo-Santa-lucia:before {
  content: '\e952';
  color: #006ff5;
}
.icon-iris-blanco .path1:before {
  content: '\e953';
  color: rgb(255, 255, 255);
}
.icon-iris-blanco .path2:before {
  content: '\e954';
  margin-left: -7.619140625em;
  color: rgb(71, 215, 172);
}
.icon-iris-blanco .path3:before {
  content: '\e955';
  margin-left: -7.619140625em;
  color: rgb(9, 110, 220);
}
.icon-iris-negro .path1:before {
  content: '\e956';
  color: rgb(40, 52, 74);
}
.icon-iris-negro .path2:before {
  content: '\e957';
  margin-left: -7.587890625em;
  color: rgb(71, 215, 172);
}
.icon-iris-negro .path3:before {
  content: '\e958';
  margin-left: -7.587890625em;
  color: rgb(9, 110, 220);
}
.icon-Santa-Lucia .path1:before {
  content: '\e959';
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path2:before {
  content: '\e95a';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path3:before {
  content: '\e95b';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path4:before {
  content: '\e95c';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path5:before {
  content: '\e95d';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path6:before {
  content: '\e95e';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path7:before {
  content: '\e95f';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path8:before {
  content: '\e960';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path9:before {
  content: '\e961';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path10:before {
  content: '\e962';
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}
.icon-Santa-Lucia .path11:before {
  content: '\e963';
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}
.icon-Santa-Lucia .path12:before {
  content: '\e964';
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}
.icon-Santa-Lucia .path13:before {
  content: '\e965';
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}
.icon-Santa-Lucia .path14:before {
  content: '\e966';
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}
.icon-Santa-Lucia .path15:before {
  content: '\e967';
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}
.icon-Santa-Lucia .path16:before {
  content: '\e968';
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}
.icon-Santa-Lucia .path17:before {
  content: '\e969';
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}
.icon-alert .path1:before {
  content: '\e96a';
  color: rgb(0, 130, 255);
}
.icon-alert .path2:before {
  content: '\e96b';
  margin-left: -1em;
  color: rgb(71, 215, 172);
}
.icon-alert .path3:before {
  content: '\e96c';
  margin-left: -1em;
  color: rgb(71, 215, 172);
}
.icon-info .path1:before {
  content: '\e96d';
  color: rgb(0, 130, 255);
}
.icon-info .path2:before {
  content: '\e96e';
  margin-left: -1em;
  color: rgb(71, 215, 172);
}
.icon-info .path3:before {
  content: '\e96f';
  margin-left: -1em;
  color: rgb(71, 215, 172);
}
.icon-OK .path1:before {
  content: '\e970';
  color: rgb(0, 130, 255);
}
.icon-OK .path2:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(71, 215, 172);
}
.icon-contact .path1:before {
  content: '\e900';
  color: rgb(70, 213, 171);
}
.icon-contact .path2:before {
  content: '\e901';
  margin-left: -0.9130859375em;
  color: rgb(0, 130, 255);
}
.icon-decesos .path1:before {
  content: '\e902';
  color: rgb(70, 213, 171);
}
.icon-decesos .path2:before {
  content: '\e903';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-decesos .path3:before {
  content: '\e904';
  margin-left: -1em;
  color: rgb(0, 120, 205);
}
.icon-decesos .path4:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(0, 120, 205);
}
.icon-decesos .path5:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-dia-a-diia .path1:before {
  content: '\e907';
  color: rgb(70, 213, 171);
}
.icon-dia-a-diia .path2:before {
  content: '\e908';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-dia-a-diia .path3:before {
  content: '\e909';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-dia-a-diia .path4:before {
  content: '\e90a';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-famila .path1:before {
  content: '\e90b';
  color: rgb(70, 213, 171);
}
.icon-famila .path2:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-famila .path3:before {
  content: '\e90d';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-famila .path4:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-famila .path5:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-hogar .path1:before {
  content: '\e910';
  color: rgb(70, 213, 171);
}
.icon-hogar .path2:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-hogar .path3:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-juridico .path1:before {
  content: '\e913';
  color: rgb(70, 213, 171);
}
.icon-juridico .path2:before {
  content: '\e914';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-juridico .path3:before {
  content: '\e915';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-juridico .path4:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-siniestros .path1:before {
  content: '\e917';
  color: rgb(0, 130, 255);
}
.icon-siniestros .path2:before {
  content: '\e918';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-siniestros .path3:before {
  content: '\e919';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-siniestros .path4:before {
  content: '\e91a';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-siniestros .path5:before {
  content: '\e91b';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-siniestros .path6:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-siniestros .path7:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-siniestros .path8:before {
  content: '\e91e';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-siniestros .path9:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-siniestros .path10:before {
  content: '\e920';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-viajes .path1:before {
  content: '\e921';
  color: rgb(70, 213, 171);
}
.icon-viajes .path2:before {
  content: '\e922';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-viajes .path3:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-viajes .path4:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(70, 213, 171);
}
.icon-viajes .path5:before {
  content: '\e925';
  margin-left: -1em;
  color: rgb(0, 130, 255);
}
.icon-adjunto:before {
  content: '\e926';
}
.icon-alert1:before {
  content: '\e927';
}
.icon-arrow-down:before {
  content: '\e928';
}
.icon-arrow-left:before {
  content: '\e929';
}
.icon-arrow-right:before {
  content: '\e92a';
}
.icon-arrow-up:before {
  content: '\e92b';
}
.icon-buscar:before {
  content: '\e92c';
}
.icon-calendar:before {
  content: '\e92d';
}
.icon-cerrar:before {
  content: '\e92e';
}
.icon-check:before {
  content: '\e92f';
}
.icon-cierre-sesion:before {
  content: '\e930';
}
.icon-contact1:before {
  content: '\e931';
}
.icon-download:before {
  content: '\e932';
}
.icon-edit:before {
  content: '\e933';
}
.icon-eliminar:before {
  content: '\e934';
}
.icon-encargo:before {
  content: '\e935';
}
.icon-facebook:before {
  content: '\e936';
}
.icon-filter:before {
  content: '\e937';
}
.icon-fuera-plazo:before {
  content: '\e938';
}
.icon-home:before {
  content: '\e939';
}
.icon-idiomas:before {
  content: '\e93a';
}
.icon-info1:before {
  content: '\e93b';
}
.icon-instagram:before {
  content: '\e93c';
}
.icon-iris:before {
  content: '\e93d';
}
.icon-linkedin:before {
  content: '\e93e';
}
.icon-liquidaciones:before {
  content: '\e93f';
}
.icon-mail:before {
  content: '\e940';
}
.icon-mas:before {
  content: '\e941';
}
.icon-menos:before {
  content: '\e942';
}
.icon-menu:before {
  content: '\e943';
}
.icon-nota-enviada:before {
  content: '\e944';
}
.icon-nota-leida:before {
  content: '\e945';
}
.icon-notification-simple:before {
  content: '\e947';
}
.icon-notification:before {
  content: '\e948';
}
.icon-ocultar:before {
  content: '\e949';
}
.icon-sede:before {
  content: '\e94a';
}
.icon-Twitter:before {
  content: '\e94b';
}
.icon-upload:before {
  content: '\e94c';
}
.icon-user:before {
  content: '\e94d';
}
.icon-visualizar:before {
  content: '\e94e';
}
.icon-youtube:before {
  content: '\e94f';
}
