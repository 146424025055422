@charset "UTF-8";
/*
  This file can be used to overwrite any normalize settings.
*/
/* Reseteamos los margin y paddings de todas las etiquetas */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  vertical-align: baseline;
}

*::before,
*::after {
  display: block;
}

/* (Opcional) Configuramos anclas suaves */
html {
  scroll-behavior: smooth;
}

/* Configuramos la tipografía para toda la web */
body {
  min-height: 100vh;
  font-size: 100%;
}

/* Desactivamos estilos por defecto de las principales etiquetas de texto */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
strong,
blockquote,
i,
b,
u,
em {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-decoration: none;
  color: inherit;
}

/* Reseteamos los enlaces para funcionar como cajas... */
a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}

/* ... excepto los que se encuentran en párrafos */
p a {
  display: inline;
}

/* Quitamos los puntos de los <li> */
li {
  list-style-type: none;
}

/* Evitamos problemas con los pseudoelementos de quotes */
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* (Opcional) Configuramos el texto que seleccionamos */
::selection {
  background-color: var(--color-primary);
  color: var(--color-white);
}

/* Nivelamos problemas de tipografías y colocación de formularios */
form,
input,
textarea,
select,
button,
label {
  font-family: inherit;
  font-size: inherit;
  hyphens: auto;
  background-color: transparent;
  color: inherit;
  display: block;
  /* (Opcional) */
}

/* Reseteamos las tablas */
table,
tr,
th,
td {
  border-collapse: collapse;
  border-spacing: 0;
  vertical-align: middle;
}

/* Evitamos problemas con las imagenes */
img,
picture,
video,
iframe,
figure {
  max-width: 100%;
  width: 100%;
  display: block;
  /* (Opcional) */
  object-fit: cover;
  /* (Opcional) */
  object-position: center center;
}

/* Evitamos problemas con los SVG */
svg {
  width: 100%;
  display: block;
  fill: currentColor;
}

/* (Opcional) Desactivamos los animations en el caso de que el usuario haya configurado el modo sin animation */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
/* (Opcional) Las adaptamos al modo oscuro */
/* (Opcional) Configuramos si un usuario ha activado el modo alto contraste. (WD) */
/** @deprecated */
:root {
  /**************/
  /*   TEXTOS   */
  /**************/
  --font-family-open-sans: Open sans, sans-serif;
  --font-size-24: 24px;
  --font-size-22: 22px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --font-size-12: 12px;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-normal: 400;
  --font-line-height-33: 33px;
  --font-line-height-28: 28px;
  --font-line-height-24: 24px;
  --font-line-height-22: 22px;
  --font-line-height-19: 19px;
  --font-line-height-17: 17px;
  --font-letter-spacing-09: 0.9px;
  --font-letter-spacing-04: 0.4px;
  --font-letter-spacing-025: 0.25px;
  --font-letter-spacing-017: 0.17px;
  --font-letter-spacing-014: 0.14px;
  --font-letter-spacing-009: 0.09;
  --font-letter-spacing-0: 0;
  /* ***************** */
  /* ***   COLORS  *** */
  /* ***************** */
  /* ***   Primary  *** */
  --color-primary: #004ab7;
  --color-primary2: #00257a;
  --color-primary3: #006fff;
  --color-primary4: #006ff540;
  --color-primary5: #006ff5;
  --color-primary6: #0082ff;
  --color-primary7: #28344a;
  --color-primary8: rgb(71, 215, 172);
  --color-primary9: #b1d4f6;
  --color-primary10: #eff7ff;
  --color-primary11: rgba(0, 130, 255, 0.1);
  --color-primary12: #e5edff;
  --color-primary13: #f6f9ff;
  --color-primary14: #f6fafc;
  --color-primary15: #cedee7;
  --color-primary16: #f2f9ff;
  /* ***   Black and grey  *** */
  --color-white: #fff;
  --color-grey: #ebebeb;
  --color-grey2: #707070;
  --color-grey3: #e5ecf7;
  --color-grey4: #ececec;
  --color-grey5: #dfdfdf;
  --color-grey6: #f1f1f1;
  --color-grey7: #f2f2f2;
  --color-grey8: #d8d8d8;
  --color-grey9: #dcdcdc;
  --color-grey10: #a6a6a6;
  --color-grey11: #979797;
  --color-grey12: #f5f5f5;
  --color-grey13: #eae9e9;
  --color-grey14: #f7f7f7;
  --color-grey15: #7a7a7a;
  --color-grey16: #fafafa;
  --color-grey17: #c4c2c2;
  --color-black: #333333;
  --color-black2: #505050;
  --color-black3: #28344a;
  --color-black4: #666666;
  --color-black5: #555555;
  --color-black6: #000000;
  /* ***   secondary  *** */
  --color-secondary: #2d373d;
  --color-secondary2: #526570;
  --color-secondary3: #9cb0bc;
  --color-secondary4: #e8ebed;
  --color-secondary5: #f5f6f7;
  --color-secondary6: #89969a;
  --color-secondary7: #f1f8ff;
  --color-secondary8: #ccf0ff;
  --color-secondary9: #d2e8fd;
  --color-selected: #f8efcd;
  /* *** type style *** */
  --color-type: #ccf0ff;
  --color-type2: #f9ecde;
  --color-type3: #e6fbd5;
  --color-type4: #ffe6d8;
  --color-type5: #47d7ac;
  --color-type6: #0082ff;
  --color-type7: #b50500;
  --color-type8: #fbf5ed;
  --color-type9: #f9ecde;
  --color-type10: #fef9f2;
  --color-type11: #bd4412;
  --color-type12: #006700;
  --color-type13: #f6d6d0;
  /* *** Measures *** */
  --sidebar-width: 248px;
}

/*
  This file is used to contain all abstracts imports.
  Files inside this folder can contain abstract settings,
  helpers or functions. They have no direct output.
*/
html,
body {
  background: var(--color-primary13);
}

.highlighted-text {
  background-color: rgba(255, 159, 0, 0.21);
}

body {
  font-family: var(--font-family-open-sans);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-line-height-19);
  letter-spacing: var(--font-letter-spacing-025);
  color: var(--color-black);
  /* (Opcional) */
  hyphens: auto;
  /* (Opcional) */
  font-smooth: always;
  /* (Opcional) */
  -webkit-font-smoothing: antialiased;
  /* (Opcional) */
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-0);
  line-height: var(--font-line-height-33);
}

h2 {
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-017);
  line-height: var(--font-line-height-28);
}

h3 {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-014);
  line-height: var(--font-line-height-24);
}

h4 {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-0);
  line-height: var(--font-line-height-22);
}

h5 {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-009);
  line-height: var(--font-line-height-19);
}

p {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--font-letter-spacing-025);
  line-height: var(--font-line-height-19);
}

a {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--font-letter-spacing-09);
  line-height: var(--font-line-height-19);
  color: var(--color-primary5);
}

strong {
  font-weight: var(--font-weight-bold);
}

/*
This file is used to contain all base imports.
Files inside this folder can contain global styles used in
the project.
*/
::ng-deep .p-overlaypanel {
  box-shadow: none;
}
::ng-deep .p-overlaypanel:before {
  display: none;
}
::ng-deep .p-overlaypanel:after {
  display: none;
}
::ng-deep .p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}

::ng-deep.p-datepicker {
  border: none;
  padding: 0;
  border-radius: 8px;
}

::ng-deep.p-datepicker .p-datepicker-header {
  border: none;
}

::ng-deep.p-datepicker .p-datepicker-header .p-link {
  display: inline-flex;
  cursor: default;
}

::ng-deep.p-datepicker .p-datepicker-group {
  border: none !important;
}

::ng-deep.p-datepicker-group-container {
  border: none;
  padding: 0;
  padding-bottom: 0;
}

::ng-deep.p-datepicker table td > span {
  cursor: default;
}

::ng-deep.p-datepicker table td.p-datepicker-today > span {
  background-color: transparent;
  border: 1px solid var(--color-primary5);
  color: var(--color-black);
}

::ng-deep.p-datepicker table td > span.p-highlight {
  color: var(--color-black);
  background: #e3f2fd;
  background-color: var(--color-secondary8);
}

/*
This file is used to contain all component imports.
Files inside this folder should contain all styles relating
to a reusable component.
*/
@font-face {
  font-family: "iris-icons";
  src: url("fonts/iris-icons.eot?5pyklb");
  src: url("fonts/iris-icons.eot?5pyklb#iefix") format("embedded-opentype"), url("fonts/iris-icons.ttf?5pyklb") format("truetype"), url("fonts/iris-icons.woff?5pyklb") format("woff"), url("fonts/iris-icons.svg?5pyklb#iris-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iris-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-nota-sin-leer:before {
  content: "\e946";
}

.icon-idiomas1:before {
  content: "\e972";
  color: #0082ff;
}

.icon-imagotipo-iris .path1:before {
  content: "\e950";
  color: rgb(71, 215, 172);
}

.icon-imagotipo-iris .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(9, 110, 220);
}

.icon-imagotipo-Santa-lucia:before {
  content: "\e952";
  color: #006ff5;
}

.icon-iris-blanco .path1:before {
  content: "\e953";
  color: rgb(255, 255, 255);
}

.icon-iris-blanco .path2:before {
  content: "\e954";
  margin-left: -7.619140625em;
  color: rgb(71, 215, 172);
}

.icon-iris-blanco .path3:before {
  content: "\e955";
  margin-left: -7.619140625em;
  color: rgb(9, 110, 220);
}

.icon-iris-negro .path1:before {
  content: "\e956";
  color: rgb(40, 52, 74);
}

.icon-iris-negro .path2:before {
  content: "\e957";
  margin-left: -7.587890625em;
  color: rgb(71, 215, 172);
}

.icon-iris-negro .path3:before {
  content: "\e958";
  margin-left: -7.587890625em;
  color: rgb(9, 110, 220);
}

.icon-Santa-Lucia .path1:before {
  content: "\e959";
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path2:before {
  content: "\e95a";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path3:before {
  content: "\e95b";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path4:before {
  content: "\e95c";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path5:before {
  content: "\e95d";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path6:before {
  content: "\e95e";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path7:before {
  content: "\e95f";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path8:before {
  content: "\e960";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path9:before {
  content: "\e961";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path10:before {
  content: "\e962";
  margin-left: -7.587890625em;
  color: rgb(0, 98, 200);
}

.icon-Santa-Lucia .path11:before {
  content: "\e963";
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}

.icon-Santa-Lucia .path12:before {
  content: "\e964";
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}

.icon-Santa-Lucia .path13:before {
  content: "\e965";
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}

.icon-Santa-Lucia .path14:before {
  content: "\e966";
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}

.icon-Santa-Lucia .path15:before {
  content: "\e967";
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}

.icon-Santa-Lucia .path16:before {
  content: "\e968";
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}

.icon-Santa-Lucia .path17:before {
  content: "\e969";
  margin-left: -7.587890625em;
  color: rgb(65, 184, 242);
}

.icon-alert .path1:before {
  content: "\e96a";
  color: rgb(0, 130, 255);
}

.icon-alert .path2:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(71, 215, 172);
}

.icon-alert .path3:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(71, 215, 172);
}

.icon-info .path1:before {
  content: "\e96d";
  color: rgb(0, 130, 255);
}

.icon-info .path2:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(71, 215, 172);
}

.icon-info .path3:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(71, 215, 172);
}

.icon-OK .path1:before {
  content: "\e970";
  color: rgb(0, 130, 255);
}

.icon-OK .path2:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(71, 215, 172);
}

.icon-contact .path1:before {
  content: "\e900";
  color: rgb(70, 213, 171);
}

.icon-contact .path2:before {
  content: "\e901";
  margin-left: -0.9130859375em;
  color: rgb(0, 130, 255);
}

.icon-decesos .path1:before {
  content: "\e902";
  color: rgb(70, 213, 171);
}

.icon-decesos .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-decesos .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(0, 120, 205);
}

.icon-decesos .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(0, 120, 205);
}

.icon-decesos .path5:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-dia-a-diia .path1:before {
  content: "\e907";
  color: rgb(70, 213, 171);
}

.icon-dia-a-diia .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-dia-a-diia .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-dia-a-diia .path4:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-famila .path1:before {
  content: "\e90b";
  color: rgb(70, 213, 171);
}

.icon-famila .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-famila .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-famila .path4:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-famila .path5:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-hogar .path1:before {
  content: "\e910";
  color: rgb(70, 213, 171);
}

.icon-hogar .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-hogar .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-juridico .path1:before {
  content: "\e913";
  color: rgb(70, 213, 171);
}

.icon-juridico .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-juridico .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-juridico .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-siniestros .path1:before {
  content: "\e917";
  color: rgb(0, 130, 255);
}

.icon-siniestros .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-siniestros .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-siniestros .path4:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-siniestros .path5:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-siniestros .path6:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-siniestros .path7:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-siniestros .path8:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-siniestros .path9:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-siniestros .path10:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-viajes .path1:before {
  content: "\e921";
  color: rgb(70, 213, 171);
}

.icon-viajes .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-viajes .path3:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-viajes .path4:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(70, 213, 171);
}

.icon-viajes .path5:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(0, 130, 255);
}

.icon-adjunto:before {
  content: "\e926";
}

.icon-alert1:before {
  content: "\e927";
}

.icon-arrow-down:before {
  content: "\e928";
}

.icon-arrow-left:before {
  content: "\e929";
}

.icon-arrow-right:before {
  content: "\e92a";
}

.icon-arrow-up:before {
  content: "\e92b";
}

.icon-buscar:before {
  content: "\e92c";
}

.icon-calendar:before {
  content: "\e92d";
}

.icon-cerrar:before {
  content: "\e92e";
}

.icon-check:before {
  content: "\e92f";
}

.icon-cierre-sesion:before {
  content: "\e930";
}

.icon-contact1:before {
  content: "\e931";
}

.icon-download:before {
  content: "\e932";
}

.icon-edit:before {
  content: "\e933";
}

.icon-eliminar:before {
  content: "\e934";
}

.icon-encargo:before {
  content: "\e935";
}

.icon-facebook:before {
  content: "\e936";
}

.icon-filter:before {
  content: "\e937";
}

.icon-fuera-plazo:before {
  content: "\e938";
}

.icon-home:before {
  content: "\e939";
}

.icon-idiomas:before {
  content: "\e93a";
}

.icon-info1:before {
  content: "\e93b";
}

.icon-instagram:before {
  content: "\e93c";
}

.icon-iris:before {
  content: "\e93d";
}

.icon-linkedin:before {
  content: "\e93e";
}

.icon-liquidaciones:before {
  content: "\e93f";
}

.icon-mail:before {
  content: "\e940";
}

.icon-mas:before {
  content: "\e941";
}

.icon-menos:before {
  content: "\e942";
}

.icon-menu:before {
  content: "\e943";
}

.icon-nota-enviada:before {
  content: "\e944";
}

.icon-nota-leida:before {
  content: "\e945";
}

.icon-notification-simple:before {
  content: "\e947";
}

.icon-notification:before {
  content: "\e948";
}

.icon-ocultar:before {
  content: "\e949";
}

.icon-sede:before {
  content: "\e94a";
}

.icon-Twitter:before {
  content: "\e94b";
}

.icon-upload:before {
  content: "\e94c";
}

.icon-user:before {
  content: "\e94d";
}

.icon-visualizar:before {
  content: "\e94e";
}

.icon-youtube:before {
  content: "\e94f";
}

/*
  This file is used to contain all icons imports.
  Files inside this folder can contain icons settings,
  helpers or functions. They have no direct output.
*/
/*
This file is used to contain all layout imports.
Files inside this folder should contain all styles relating
to layouts.
*/
/* Scrollbar: Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-grey8);
  border-radius: 20px;
  width: 3px;
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--color-grey9);
}

/* Scrollbar: Firefox */
* {
  scrollbar-color: var(--color-grey8);
}

/*
This file is used to contain all vendor imports.
*/
.p-component, .p-component * {
  box-sizing: border-box;
}

.p-hidden {
  display: none;
}

.p-hidden-space {
  visibility: hidden;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-hidden-accessible input, .p-hidden-accessible select {
  transform: scale(0);
}

.p-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.p-disabled, .p-disabled * {
  cursor: default !important;
  pointer-events: none;
}

.p-component-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-overflow-hidden {
  overflow: hidden;
}

.p-unselectable-text {
  user-select: none;
}

.p-scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

@-webkit-keyframes p-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes p-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
input[type=button], input[type=submit], input[type=reset], input[type=file]::-webkit-file-upload-button, button {
  border-radius: 0;
}

.p-link {
  text-align: left;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  user-select: none;
}

.p-link:disabled {
  cursor: default;
}

.p-sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.p-connected-overlay {
  opacity: 0;
  transform: scaleY(0.8);
  transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
}

.p-connected-overlay-visible {
  opacity: 1;
  transform: scaleY(1);
}

.p-connected-overlay-hidden {
  opacity: 0;
  transform: scaleY(1);
  transition: opacity 0.1s linear;
}

.p-toggleable-content.ng-animating {
  overflow: hidden;
}

.p-badge {
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  padding: 0 0.5rem;
}

.p-overlay-badge {
  position: relative;
}

.p-overlay-badge .p-badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0;
  margin: 0;
}

.p-badge-dot {
  width: 0.5rem;
  min-width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  padding: 0;
}

.p-badge-no-gutter {
  padding: 0;
  border-radius: 50%;
}

.p-button {
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.p-button-label {
  flex: 1 1 auto;
}

.p-button-icon-right {
  order: 1;
}

.p-button:disabled {
  cursor: default;
}

.p-button-icon-only {
  justify-content: center;
}

.p-button-icon-only:after {
  content: "p";
  visibility: hidden;
  clip: rect(0 0 0 0);
  width: 0;
}

.p-button-vertical {
  flex-direction: column;
}

.p-button-icon-bottom {
  order: 2;
}

.p-buttonset .p-button {
  margin: 0;
}

.p-buttonset .p-button:not(:last-child) {
  border-right: 0 none;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-buttonset .p-button:focus {
  position: relative;
  z-index: 1;
}

.p-checkbox {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  vertical-align: bottom;
  position: relative;
}

.p-checkbox-disabled {
  cursor: default !important;
  pointer-events: none;
}

.p-checkbox-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

p-checkbox {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
}

.p-checkbox-label {
  line-height: 1;
}

.p-colorpicker-panel .p-colorpicker-color {
  background: transparent url("./images/color.png") no-repeat left top;
}

.p-colorpicker-panel .p-colorpicker-hue {
  background: transparent url("./images/hue.png") no-repeat left top;
}

.p-inputtext {
  margin: 0;
}

.p-fluid .p-inputtext {
  width: 100%;
}

.p-inputgroup {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.p-inputgroup-addon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-inputgroup .p-float-label {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-inputgroup .p-inputwrapper > .p-component {
  flex: 1 1 auto;
  width: 1%;
}

.p-float-label {
  display: block;
  position: relative;
}

.p-float-label label {
  position: absolute;
  pointer-events: none;
  top: 50%;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
}

.p-float-label textarea ~ label {
  top: 1rem;
}

.p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
  top: -0.75rem;
  font-size: 12px;
}

.p-float-label .input:-webkit-autofill ~ label {
  top: -20px;
  font-size: 12px;
}

.p-float-label .p-placeholder, .p-float-label input::placeholder, .p-float-label .p-inputtext::placeholder {
  opacity: 0;
  transition-property: all;
  transition-timing-function: ease;
}

.p-float-label .p-focus .p-placeholder, .p-float-label input:focus::placeholder, .p-float-label .p-inputtext:focus::placeholder {
  opacity: 1;
  transition-property: all;
  transition-timing-function: ease;
}

.p-input-icon-left, .p-input-icon-right {
  position: relative;
  display: inline-block;
}

.p-input-icon-left > i, .p-input-icon-right > i {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-fluid .p-input-icon-left, .p-fluid .p-input-icon-right {
  display: block;
  width: 100%;
}

.p-inputtextarea-resizable {
  overflow: hidden;
  resize: none;
}

.p-fluid .p-inputtextarea {
  width: 100%;
}

.p-password {
  position: relative;
  display: inline-flex;
}

.p-password-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-password .p-password-panel {
  min-width: 100%;
}

.p-password-meter {
  height: 10px;
}

.p-password-strength {
  height: 100%;
  width: 0;
  transition: width 1s ease-in-out;
}

.p-fluid .p-password {
  display: flex;
}

.p-password-input::-ms-reveal, .p-password-input::-ms-clear {
  display: none;
}

.p-password-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  cursor: pointer;
}

.p-password-clearable {
  position: relative;
}

.p-radiobutton {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  vertical-align: bottom;
  position: relative;
}

.p-radiobutton-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-radiobutton-icon {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0) scale(0.1);
  border-radius: 50%;
  visibility: hidden;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1, 1);
  visibility: visible;
}

p-radiobutton {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
}

.p-radiobutton-label {
  line-height: 1;
}

.p-ripple {
  overflow: hidden;
  position: relative;
}

.p-ink {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  transform: scale(0);
}

.p-ink-active {
  animation: ripple 0.4s linear;
}

.p-ripple-disabled .p-ink {
  display: none !important;
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
.p-tooltip {
  position: absolute;
  display: none;
  padding: 0.25em 0.5rem;
  max-width: 12.5rem;
}

.p-tooltip.p-tooltip-right, .p-tooltip.p-tooltip-left {
  padding: 0 0.25rem;
}

.p-tooltip.p-tooltip-top, .p-tooltip.p-tooltip-bottom {
  padding: 0.25em 0;
}

.p-tooltip .p-tooltip-text {
  white-space: pre-line;
  word-break: break-word;
}

.p-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.p-tooltip-right .p-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -0.25rem;
  border-width: 0.25em 0.25em 0.25em 0;
}

.p-tooltip-left .p-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -0.25rem;
  border-width: 0.25em 0 0.25em 0.25rem;
}

.p-tooltip.p-tooltip-top {
  padding: 0.25em 0;
}

.p-tooltip-top .p-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0.25em 0.25em 0;
}

.p-tooltip-bottom .p-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0 0.25em 0.25rem;
}

@font-face {
  font-family: "primeicons";
  font-display: block;
  src: url("./fonts/primeicons.eot");
  src: url("./fonts/primeicons.eot?#iefix") format("embedded-opentype"), url("./fonts/primeicons.woff2") format("woff2"), url("./fonts/primeicons.woff") format("woff"), url("./fonts/primeicons.ttf") format("truetype"), url("./fonts/primeicons.svg?#primeicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.pi {
  font-family: "primeicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi:before {
  --webkit-backface-visibility:hidden;
  backface-visibility: hidden;
}

.pi-fw {
  width: 1.28571429em;
  text-align: center;
}

.pi-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.pi-eraser:before {
  content: "\ea04";
}

.pi-stopwatch:before {
  content: "\ea01";
}

.pi-verified:before {
  content: "\ea02";
}

.pi-delete-left:before {
  content: "\ea03";
}

.pi-hourglass:before {
  content: "\e9fe";
}

.pi-truck:before {
  content: "\ea00";
}

.pi-wrench:before {
  content: "\e9ff";
}

.pi-microphone:before {
  content: "\e9fa";
}

.pi-megaphone:before {
  content: "\e9fb";
}

.pi-arrow-right-arrow-left:before {
  content: "\e9fc";
}

.pi-bitcoin:before {
  content: "\e9fd";
}

.pi-file-edit:before {
  content: "\e9f6";
}

.pi-language:before {
  content: "\e9f7";
}

.pi-file-export:before {
  content: "\e9f8";
}

.pi-file-import:before {
  content: "\e9f9";
}

.pi-file-word:before {
  content: "\e9f1";
}

.pi-gift:before {
  content: "\e9f2";
}

.pi-cart-plus:before {
  content: "\e9f3";
}

.pi-thumbs-down-fill:before {
  content: "\e9f4";
}

.pi-thumbs-up-fill:before {
  content: "\e9f5";
}

.pi-arrows-alt:before {
  content: "\e9f0";
}

.pi-calculator:before {
  content: "\e9ef";
}

.pi-sort-alt-slash:before {
  content: "\e9ee";
}

.pi-arrows-h:before {
  content: "\e9ec";
}

.pi-arrows-v:before {
  content: "\e9ed";
}

.pi-pound:before {
  content: "\e9eb";
}

.pi-prime:before {
  content: "\e9ea";
}

.pi-chart-pie:before {
  content: "\e9e9";
}

.pi-reddit:before {
  content: "\e9e8";
}

.pi-code:before {
  content: "\e9e7";
}

.pi-sync:before {
  content: "\e9e6";
}

.pi-shopping-bag:before {
  content: "\e9e5";
}

.pi-server:before {
  content: "\e9e4";
}

.pi-database:before {
  content: "\e9e3";
}

.pi-hashtag:before {
  content: "\e9e2";
}

.pi-bookmark-fill:before {
  content: "\e9df";
}

.pi-filter-fill:before {
  content: "\e9e0";
}

.pi-heart-fill:before {
  content: "\e9e1";
}

.pi-flag-fill:before {
  content: "\e9de";
}

.pi-circle:before {
  content: "\e9dc";
}

.pi-circle-fill:before {
  content: "\e9dd";
}

.pi-bolt:before {
  content: "\e9db";
}

.pi-history:before {
  content: "\e9da";
}

.pi-box:before {
  content: "\e9d9";
}

.pi-at:before {
  content: "\e9d8";
}

.pi-arrow-up-right:before {
  content: "\e9d4";
}

.pi-arrow-up-left:before {
  content: "\e9d5";
}

.pi-arrow-down-left:before {
  content: "\e9d6";
}

.pi-arrow-down-right:before {
  content: "\e9d7";
}

.pi-telegram:before {
  content: "\e9d3";
}

.pi-stop-circle:before {
  content: "\e9d2";
}

.pi-stop:before {
  content: "\e9d1";
}

.pi-whatsapp:before {
  content: "\e9d0";
}

.pi-building:before {
  content: "\e9cf";
}

.pi-qrcode:before {
  content: "\e9ce";
}

.pi-car:before {
  content: "\e9cd";
}

.pi-instagram:before {
  content: "\e9cc";
}

.pi-linkedin:before {
  content: "\e9cb";
}

.pi-send:before {
  content: "\e9ca";
}

.pi-slack:before {
  content: "\e9c9";
}

.pi-sun:before {
  content: "\e9c8";
}

.pi-moon:before {
  content: "\e9c7";
}

.pi-vimeo:before {
  content: "\e9c6";
}

.pi-youtube:before {
  content: "\e9c5";
}

.pi-flag:before {
  content: "\e9c4";
}

.pi-wallet:before {
  content: "\e9c3";
}

.pi-map:before {
  content: "\e9c2";
}

.pi-link:before {
  content: "\e9c1";
}

.pi-credit-card:before {
  content: "\e9bf";
}

.pi-discord:before {
  content: "\e9c0";
}

.pi-percentage:before {
  content: "\e9be";
}

.pi-euro:before {
  content: "\e9bd";
}

.pi-book:before {
  content: "\e9ba";
}

.pi-shield:before {
  content: "\e9b9";
}

.pi-paypal:before {
  content: "\e9bb";
}

.pi-amazon:before {
  content: "\e9bc";
}

.pi-phone:before {
  content: "\e9b8";
}

.pi-filter-slash:before {
  content: "\e9b7";
}

.pi-facebook:before {
  content: "\e9b4";
}

.pi-github:before {
  content: "\e9b5";
}

.pi-twitter:before {
  content: "\e9b6";
}

.pi-step-backward-alt:before {
  content: "\e9ac";
}

.pi-step-forward-alt:before {
  content: "\e9ad";
}

.pi-forward:before {
  content: "\e9ae";
}

.pi-backward:before {
  content: "\e9af";
}

.pi-fast-backward:before {
  content: "\e9b0";
}

.pi-fast-forward:before {
  content: "\e9b1";
}

.pi-pause:before {
  content: "\e9b2";
}

.pi-play:before {
  content: "\e9b3";
}

.pi-compass:before {
  content: "\e9ab";
}

.pi-id-card:before {
  content: "\e9aa";
}

.pi-ticket:before {
  content: "\e9a9";
}

.pi-file-o:before {
  content: "\e9a8";
}

.pi-reply:before {
  content: "\e9a7";
}

.pi-directions-alt:before {
  content: "\e9a5";
}

.pi-directions:before {
  content: "\e9a6";
}

.pi-thumbs-up:before {
  content: "\e9a3";
}

.pi-thumbs-down:before {
  content: "\e9a4";
}

.pi-sort-numeric-down-alt:before {
  content: "\e996";
}

.pi-sort-numeric-up-alt:before {
  content: "\e997";
}

.pi-sort-alpha-down-alt:before {
  content: "\e998";
}

.pi-sort-alpha-up-alt:before {
  content: "\e999";
}

.pi-sort-numeric-down:before {
  content: "\e99a";
}

.pi-sort-numeric-up:before {
  content: "\e99b";
}

.pi-sort-alpha-down:before {
  content: "\e99c";
}

.pi-sort-alpha-up:before {
  content: "\e99d";
}

.pi-sort-alt:before {
  content: "\e99e";
}

.pi-sort-amount-up:before {
  content: "\e99f";
}

.pi-sort-amount-down:before {
  content: "\e9a0";
}

.pi-sort-amount-down-alt:before {
  content: "\e9a1";
}

.pi-sort-amount-up-alt:before {
  content: "\e9a2";
}

.pi-palette:before {
  content: "\e995";
}

.pi-undo:before {
  content: "\e994";
}

.pi-desktop:before {
  content: "\e993";
}

.pi-sliders-v:before {
  content: "\e991";
}

.pi-sliders-h:before {
  content: "\e992";
}

.pi-search-plus:before {
  content: "\e98f";
}

.pi-search-minus:before {
  content: "\e990";
}

.pi-file-excel:before {
  content: "\e98e";
}

.pi-file-pdf:before {
  content: "\e98d";
}

.pi-check-square:before {
  content: "\e98c";
}

.pi-chart-line:before {
  content: "\e98b";
}

.pi-user-edit:before {
  content: "\e98a";
}

.pi-exclamation-circle:before {
  content: "\e989";
}

.pi-android:before {
  content: "\e985";
}

.pi-google:before {
  content: "\e986";
}

.pi-apple:before {
  content: "\e987";
}

.pi-microsoft:before {
  content: "\e988";
}

.pi-heart:before {
  content: "\e984";
}

.pi-mobile:before {
  content: "\e982";
}

.pi-tablet:before {
  content: "\e983";
}

.pi-key:before {
  content: "\e981";
}

.pi-shopping-cart:before {
  content: "\e980";
}

.pi-comments:before {
  content: "\e97e";
}

.pi-comment:before {
  content: "\e97f";
}

.pi-briefcase:before {
  content: "\e97d";
}

.pi-bell:before {
  content: "\e97c";
}

.pi-paperclip:before {
  content: "\e97b";
}

.pi-share-alt:before {
  content: "\e97a";
}

.pi-envelope:before {
  content: "\e979";
}

.pi-volume-down:before {
  content: "\e976";
}

.pi-volume-up:before {
  content: "\e977";
}

.pi-volume-off:before {
  content: "\e978";
}

.pi-eject:before {
  content: "\e975";
}

.pi-money-bill:before {
  content: "\e974";
}

.pi-images:before {
  content: "\e973";
}

.pi-image:before {
  content: "\e972";
}

.pi-sign-in:before {
  content: "\e970";
}

.pi-sign-out:before {
  content: "\e971";
}

.pi-wifi:before {
  content: "\e96f";
}

.pi-sitemap:before {
  content: "\e96e";
}

.pi-chart-bar:before {
  content: "\e96d";
}

.pi-camera:before {
  content: "\e96c";
}

.pi-dollar:before {
  content: "\e96b";
}

.pi-lock-open:before {
  content: "\e96a";
}

.pi-table:before {
  content: "\e969";
}

.pi-map-marker:before {
  content: "\e968";
}

.pi-list:before {
  content: "\e967";
}

.pi-eye-slash:before {
  content: "\e965";
}

.pi-eye:before {
  content: "\e966";
}

.pi-folder-open:before {
  content: "\e964";
}

.pi-folder:before {
  content: "\e963";
}

.pi-video:before {
  content: "\e962";
}

.pi-inbox:before {
  content: "\e961";
}

.pi-lock:before {
  content: "\e95f";
}

.pi-unlock:before {
  content: "\e960";
}

.pi-tags:before {
  content: "\e95d";
}

.pi-tag:before {
  content: "\e95e";
}

.pi-power-off:before {
  content: "\e95c";
}

.pi-save:before {
  content: "\e95b";
}

.pi-question-circle:before {
  content: "\e959";
}

.pi-question:before {
  content: "\e95a";
}

.pi-copy:before {
  content: "\e957";
}

.pi-file:before {
  content: "\e958";
}

.pi-clone:before {
  content: "\e955";
}

.pi-calendar-times:before {
  content: "\e952";
}

.pi-calendar-minus:before {
  content: "\e953";
}

.pi-calendar-plus:before {
  content: "\e954";
}

.pi-ellipsis-v:before {
  content: "\e950";
}

.pi-ellipsis-h:before {
  content: "\e951";
}

.pi-bookmark:before {
  content: "\e94e";
}

.pi-globe:before {
  content: "\e94f";
}

.pi-replay:before {
  content: "\e94d";
}

.pi-filter:before {
  content: "\e94c";
}

.pi-print:before {
  content: "\e94b";
}

.pi-align-right:before {
  content: "\e946";
}

.pi-align-left:before {
  content: "\e947";
}

.pi-align-center:before {
  content: "\e948";
}

.pi-align-justify:before {
  content: "\e949";
}

.pi-cog:before {
  content: "\e94a";
}

.pi-cloud-download:before {
  content: "\e943";
}

.pi-cloud-upload:before {
  content: "\e944";
}

.pi-cloud:before {
  content: "\e945";
}

.pi-pencil:before {
  content: "\e942";
}

.pi-users:before {
  content: "\e941";
}

.pi-clock:before {
  content: "\e940";
}

.pi-user-minus:before {
  content: "\e93e";
}

.pi-user-plus:before {
  content: "\e93f";
}

.pi-trash:before {
  content: "\e93d";
}

.pi-external-link:before {
  content: "\e93c";
}

.pi-window-maximize:before {
  content: "\e93b";
}

.pi-window-minimize:before {
  content: "\e93a";
}

.pi-refresh:before {
  content: "\e938";
}

.pi-user:before {
  content: "\e939";
}

.pi-exclamation-triangle:before {
  content: "\e922";
}

.pi-calendar:before {
  content: "\e927";
}

.pi-chevron-circle-left:before {
  content: "\e928";
}

.pi-chevron-circle-down:before {
  content: "\e929";
}

.pi-chevron-circle-right:before {
  content: "\e92a";
}

.pi-chevron-circle-up:before {
  content: "\e92b";
}

.pi-angle-double-down:before {
  content: "\e92c";
}

.pi-angle-double-left:before {
  content: "\e92d";
}

.pi-angle-double-right:before {
  content: "\e92e";
}

.pi-angle-double-up:before {
  content: "\e92f";
}

.pi-angle-down:before {
  content: "\e930";
}

.pi-angle-left:before {
  content: "\e931";
}

.pi-angle-right:before {
  content: "\e932";
}

.pi-angle-up:before {
  content: "\e933";
}

.pi-upload:before {
  content: "\e934";
}

.pi-download:before {
  content: "\e956";
}

.pi-ban:before {
  content: "\e935";
}

.pi-star-fill:before {
  content: "\e936";
}

.pi-star:before {
  content: "\e937";
}

.pi-chevron-left:before {
  content: "\e900";
}

.pi-chevron-right:before {
  content: "\e901";
}

.pi-chevron-down:before {
  content: "\e902";
}

.pi-chevron-up:before {
  content: "\e903";
}

.pi-caret-left:before {
  content: "\e904";
}

.pi-caret-right:before {
  content: "\e905";
}

.pi-caret-down:before {
  content: "\e906";
}

.pi-caret-up:before {
  content: "\e907";
}

.pi-search:before {
  content: "\e908";
}

.pi-check:before {
  content: "\e909";
}

.pi-check-circle:before {
  content: "\e90a";
}

.pi-times:before {
  content: "\e90b";
}

.pi-times-circle:before {
  content: "\e90c";
}

.pi-plus:before {
  content: "\e90d";
}

.pi-plus-circle:before {
  content: "\e90e";
}

.pi-minus:before {
  content: "\e90f";
}

.pi-minus-circle:before {
  content: "\e910";
}

.pi-circle-on:before {
  content: "\e911";
}

.pi-circle-off:before {
  content: "\e912";
}

.pi-sort-down:before {
  content: "\e913";
}

.pi-sort-up:before {
  content: "\e914";
}

.pi-sort:before {
  content: "\e915";
}

.pi-step-backward:before {
  content: "\e916";
}

.pi-step-forward:before {
  content: "\e917";
}

.pi-th-large:before {
  content: "\e918";
}

.pi-arrow-down:before {
  content: "\e919";
}

.pi-arrow-left:before {
  content: "\e91a";
}

.pi-arrow-right:before {
  content: "\e91b";
}

.pi-arrow-up:before {
  content: "\e91c";
}

.pi-bars:before {
  content: "\e91d";
}

.pi-arrow-circle-down:before {
  content: "\e91e";
}

.pi-arrow-circle-left:before {
  content: "\e91f";
}

.pi-arrow-circle-right:before {
  content: "\e920";
}

.pi-arrow-circle-up:before {
  content: "\e921";
}

.pi-info:before {
  content: "\e923";
}

.pi-info-circle:before {
  content: "\e924";
}

.pi-home:before {
  content: "\e925";
}

.pi-spinner:before {
  content: "\e926";
}

:root {
  --surface-a:#ffffff;
  --surface-b:#f8f9fa;
  --surface-c:#e9ecef;
  --surface-d:#dee2e6;
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --text-color:#495057;
  --text-color-secondary:#6c757d;
  --primary-color:#2196F3;
  --primary-color-text:#ffffff;
  --font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --surface-0: #ffffff;
  --surface-50: #FAFAFA;
  --surface-100: #F5F5F5;
  --surface-200: #EEEEEE;
  --surface-300: #E0E0E0;
  --surface-400: #BDBDBD;
  --surface-500: #9E9E9E;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --gray-50: #FAFAFA;
  --gray-100: #F5F5F5;
  --gray-200: #EEEEEE;
  --gray-300: #E0E0E0;
  --gray-400: #BDBDBD;
  --gray-500: #9E9E9E;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding:1rem;
  --inline-spacing:0.5rem;
  --border-radius:3px;
  --surface-ground:#f8f9fa;
  --surface-section:#ffffff;
  --surface-card:#ffffff;
  --surface-overlay:#ffffff;
  --surface-border:#dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: rgba(0, 0, 0, 0.4);
  --focus-ring: 0 0 0 0.2rem #a6d5fa;
  color-scheme: light;
}

* {
  box-sizing: border-box;
}

.p-component {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.6;
}

.p-error {
  color: #f44336;
}

.p-text-secondary {
  color: #6c757d;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 3px;
}

.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}
:root {
  --blue-50:#f4fafe;
  --blue-100:#cae6fc;
  --blue-200:#a0d2fa;
  --blue-300:#75bef8;
  --blue-400:#4baaf5;
  --blue-500:#2196f3;
  --blue-600:#1c80cf;
  --blue-700:#1769aa;
  --blue-800:#125386;
  --blue-900:#0d3c61;
  --green-50:#f6fbf6;
  --green-100:#d4ecd5;
  --green-200:#b2ddb4;
  --green-300:#90cd93;
  --green-400:#6ebe71;
  --green-500:#4caf50;
  --green-600:#419544;
  --green-700:#357b38;
  --green-800:#2a602c;
  --green-900:#1e4620;
  --yellow-50:#fffcf5;
  --yellow-100:#fef0cd;
  --yellow-200:#fde4a5;
  --yellow-300:#fdd87d;
  --yellow-400:#fccc55;
  --yellow-500:#fbc02d;
  --yellow-600:#d5a326;
  --yellow-700:#b08620;
  --yellow-800:#8a6a19;
  --yellow-900:#644d12;
  --cyan-50:#f2fcfd;
  --cyan-100:#c2eff5;
  --cyan-200:#91e2ed;
  --cyan-300:#61d5e4;
  --cyan-400:#30c9dc;
  --cyan-500:#00bcd4;
  --cyan-600:#00a0b4;
  --cyan-700:#008494;
  --cyan-800:#006775;
  --cyan-900:#004b55;
  --pink-50:#fef4f7;
  --pink-100:#fac9da;
  --pink-200:#f69ebc;
  --pink-300:#f1749e;
  --pink-400:#ed4981;
  --pink-500:#e91e63;
  --pink-600:#c61a54;
  --pink-700:#a31545;
  --pink-800:#801136;
  --pink-900:#5d0c28;
  --indigo-50:#f5f6fb;
  --indigo-100:#d1d5ed;
  --indigo-200:#acb4df;
  --indigo-300:#8893d1;
  --indigo-400:#6372c3;
  --indigo-500:#3f51b5;
  --indigo-600:#36459a;
  --indigo-700:#2c397f;
  --indigo-800:#232d64;
  --indigo-900:#192048;
  --teal-50:#f2faf9;
  --teal-100:#c2e6e2;
  --teal-200:#91d2cc;
  --teal-300:#61beb5;
  --teal-400:#30aa9f;
  --teal-500:#009688;
  --teal-600:#008074;
  --teal-700:#00695f;
  --teal-800:#00534b;
  --teal-900:#003c36;
  --orange-50:#fff8f2;
  --orange-100:#fde0c2;
  --orange-200:#fbc791;
  --orange-300:#f9ae61;
  --orange-400:#f79530;
  --orange-500:#f57c00;
  --orange-600:#d06900;
  --orange-700:#ac5700;
  --orange-800:#874400;
  --orange-900:#623200;
  --bluegray-50:#f7f9f9;
  --bluegray-100:#d9e0e3;
  --bluegray-200:#bbc7cd;
  --bluegray-300:#9caeb7;
  --bluegray-400:#7e96a1;
  --bluegray-500:#607d8b;
  --bluegray-600:#526a76;
  --bluegray-700:#435861;
  --bluegray-800:#35454c;
  --bluegray-900:#263238;
  --purple-50:#faf4fb;
  --purple-100:#e7cbec;
  --purple-200:#d4a2dd;
  --purple-300:#c279ce;
  --purple-400:#af50bf;
  --purple-500:#9c27b0;
  --purple-600:#852196;
  --purple-700:#6d1b7b;
  --purple-800:#561561;
  --purple-900:#3e1046;
  --red-50:#fff5f5;
  --red-100:#ffd1ce;
  --red-200:#ffada7;
  --red-300:#ff8980;
  --red-400:#ff6459;
  --red-500:#ff4032;
  --red-600:#d9362b;
  --red-700:#b32d23;
  --red-800:#8c231c;
  --red-900:#661a14;
  --primary-50:#f4fafe;
  --primary-100:#cae6fc;
  --primary-200:#a0d2fa;
  --primary-300:#75bef8;
  --primary-400:#4baaf5;
  --primary-500:#2196f3;
  --primary-600:#1c80cf;
  --primary-700:#1769aa;
  --primary-800:#125386;
  --primary-900:#0d3c61;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.5rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.857rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #2196F3;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  padding: 0;
  margin: 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.5rem;
  background: #E3F2FD;
  color: #495057;
  border-radius: 3px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #f44336;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #495057;
  background: #e9ecef;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}

p-autocomplete.p-autocomplete-clearable .p-inputtext {
  padding-right: 2rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
  color: #6c757d;
  right: 0.5rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
  color: #6c757d;
  right: 2.857rem;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #f44336;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 3px;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}

.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #495057;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 600;
  padding: 0.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #2196F3;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}

.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.p-datepicker table th {
  padding: 0.5rem;
}

.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}

.p-datepicker table td {
  padding: 0.5rem;
}

.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}

.p-datepicker table td > span.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #495057;
  border-color: transparent;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}

.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}

.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}

.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2rem;
}

p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: #6c757d;
  right: 0.5rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: #6c757d;
  right: 2.857rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

.p-cascadeselect:not(.p-disabled):hover {
  border-color: #2196F3;
}

.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.5rem 0.5rem;
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #6c757d;
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.5rem 1rem;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #f44336;
}

.p-input-filled .p-cascadeselect {
  background: #f8f9fa;
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
  padding-right: 0.5rem;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

.p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
  box-shadow: none;
  border-radius: 0;
  padding: 0.25rem 0 0.25rem 0.5rem;
}

.p-overlay-modal .p-cascadeselect-item-active > .p-cascadeselect-item-content .p-cascadeselect-group-icon {
  transform: rotate(90deg);
}

.p-checkbox {
  width: 20px;
  height: 20px;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #2196F3;
  background: #2196F3;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #2196F3;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #0b7ad1;
  background: #0b7ad1;
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #f44336;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f8f9fa;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #2196F3;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #0b7ad1;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #2196F3;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.5rem;
  background: #E3F2FD;
  color: #495057;
  border-radius: 3px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #f44336;
}

p-chips.p-chips-clearable .p-inputtext {
  padding-right: 1.5rem;
}

p-chips.p-chips-clearable .p-chips-clear-icon {
  color: #6c757d;
  right: 0.5rem;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border: 1px solid #191919;
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #2196F3;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.5rem;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}

.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.5rem;
  margin-right: -1.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #f44336;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0.5rem 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #495057;
  background: #e9ecef;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 1rem;
}

.p-editor-container .p-editor-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}

.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #2196F3;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #2196F3;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #2196F3;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #2196F3;
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.5rem;
  min-width: 2.357rem;
}

.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}

.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #f44336;
}

p-inputmask.p-inputmask-clearable .p-inputtext {
  padding-right: 2rem;
}

p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
  color: #6c757d;
  right: 0.5rem;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #f44336;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
  padding-right: 2rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
  color: #6c757d;
  right: 0.5rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
  right: 2.857rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
  right: 2.857rem;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #b6bfc8;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #2196F3;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #0d89ec;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #f44336;
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 3px;
}

.p-inputtext:enabled:hover {
  border-color: #2196F3;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: #f44336;
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

.p-float-label > label {
  left: 0.5rem;
  color: #6c757d;
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #f44336;
}

.p-input-icon-left > i:first-of-type {
  left: 0.5rem;
  color: #6c757d;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2rem;
}

.p-input-icon-right > i:last-of-type {
  right: 0.5rem;
  color: #6c757d;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2rem;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-moz-placeholder {
  color: #6c757d;
}

::-moz-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

.p-input-filled .p-inputtext {
  background-color: #f8f9fa;
}

.p-input-filled .p-inputtext:enabled:hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-inputtext:enabled:focus {
  background-color: #ffffff;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 3px;
}

.p-listbox .p-listbox-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.5rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #f44336;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #2196F3;
}

.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}

.p-multiselect.p-multiselect-chip .p-multiselect-label {
  gap: 0.5rem;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.5rem;
  background: #E3F2FD;
  color: #495057;
  border-radius: 3px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.5rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #f44336;
}

.p-input-filled .p-multiselect {
  background: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: 1.5rem;
}

p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: #f44336;
}

.p-password-panel {
  padding: 1rem;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: #dee2e6;
}

.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #D32F2F;
}

.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #FBC02D;
}

.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #689F38;
}

p-password.p-password-clearable .p-password-input {
  padding-right: 2rem;
}

p-password.p-password-clearable .p-password-clear-icon {
  color: #6c757d;
  right: 0.5rem;
}

p-password.p-password-clearable.p-password-mask .p-password-input {
  padding-right: 3.5rem;
}

p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
  color: #6c757d;
  right: 2rem;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #2196F3;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #2196F3;
  background: #2196F3;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #0b7ad1;
  background: #0b7ad1;
  color: #ffffff;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #f44336;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f8f9fa;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #2196F3;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #0b7ad1;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: #495057;
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 1.143rem;
}

.p-rating .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}

.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}

.p-rating .p-rating-icon.pi-star-fill {
  color: #2196F3;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #2196F3;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}

.p-selectbutton .p-button.p-highlight {
  background: #2196F3;
  border-color: #2196F3;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #0d89ec;
  border-color: #0d89ec;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #f44336;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: 3px;
}

.p-slider.p-slider-horizontal {
  height: 0.286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
  width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid #2196F3;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-slider .p-slider-range {
  background: #2196F3;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #2196F3;
  border-color: #2196F3;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #6c757d;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}

.p-togglebutton.p-button.p-highlight {
  background: #2196F3;
  border-color: #2196F3;
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover {
  background: #0d89ec;
  border-color: #0d89ec;
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #f44336;
}

.p-treeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

.p-treeselect:not(.p-disabled):hover {
  border-color: #2196F3;
}

.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-treeselect .p-treeselect-label {
  padding: 0.5rem 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}

.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #f44336;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.25rem 0.5rem;
}

.p-treeselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-treeselect-panel .p-treeselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: 1.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: 3rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  right: 2rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #f8f9fa;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  padding-right: 1.5rem;
}

p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

.p-button {
  color: #ffffff;
  background: #2196F3;
  border: 1px solid #2196F3;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

.p-button:enabled:hover {
  background: #0d89ec;
  color: #ffffff;
  border-color: #0d89ec;
}

.p-button:enabled:active {
  background: #0b7ad1;
  color: #ffffff;
  border-color: #0b7ad1;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: #2196F3;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover {
  background: rgba(33, 150, 243, 0.04);
  color: #2196F3;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:active {
  background: rgba(33, 150, 243, 0.16);
  color: #2196F3;
  border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}

.p-button.p-button-text {
  background-color: transparent;
  color: #2196F3;
  border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(33, 150, 243, 0.04);
  color: #2196F3;
  border-color: transparent;
}

.p-button.p-button-text:enabled:active {
  background: rgba(33, 150, 243, 0.16);
  color: #2196F3;
  border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-button .p-button-label {
  transition-duration: 0.2s;
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}

.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #2196F3;
  background-color: #ffffff;
}

.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.875rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}

.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 1.25rem;
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}

.p-fluid .p-button-icon-only {
  width: 2.357rem;
}

.p-fluid .p-buttonset {
  display: flex;
}

.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #607D8B;
  border: 1px solid #607D8B;
}

.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #56717d;
  color: #ffffff;
  border-color: #56717d;
}

.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #beccd2;
}

.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #4d646f;
  color: #ffffff;
  border-color: #4d646f;
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #607D8B;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  color: #607D8B;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  color: #607D8B;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #607D8B;
  border-color: transparent;
}

.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  border-color: transparent;
  color: #607D8B;
}

.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  border-color: transparent;
  color: #607D8B;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #0288D1;
  border: 1px solid #0288D1;
}

.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #027abc;
  color: #ffffff;
  border-color: #027abc;
}

.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #89d4fe;
}

.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #026da7;
  color: #ffffff;
  border-color: #026da7;
}

.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #0288D1;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(2, 136, 209, 0.04);
  color: #0288D1;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(2, 136, 209, 0.16);
  color: #0288D1;
  border: 1px solid;
}

.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #0288D1;
  border-color: transparent;
}

.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(2, 136, 209, 0.04);
  border-color: transparent;
  color: #0288D1;
}

.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(2, 136, 209, 0.16);
  border-color: transparent;
  color: #0288D1;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #689F38;
  border: 1px solid #689F38;
}

.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #5e8f32;
  color: #ffffff;
  border-color: #5e8f32;
}

.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #c2e0a8;
}

.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #537f2d;
  color: #ffffff;
  border-color: #537f2d;
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #689F38;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(104, 159, 56, 0.04);
  color: #689F38;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(104, 159, 56, 0.16);
  color: #689F38;
  border: 1px solid;
}

.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #689F38;
  border-color: transparent;
}

.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(104, 159, 56, 0.04);
  border-color: transparent;
  color: #689F38;
}

.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(104, 159, 56, 0.16);
  border-color: transparent;
  color: #689F38;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #212529;
  background: #FBC02D;
  border: 1px solid #FBC02D;
}

.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #fab710;
  color: #212529;
  border-color: #fab710;
}

.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #fde6ab;
}

.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #e8a704;
  color: #212529;
  border-color: #e8a704;
}

.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #FBC02D;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(251, 192, 45, 0.04);
  color: #FBC02D;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(251, 192, 45, 0.16);
  color: #FBC02D;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #FBC02D;
  border-color: transparent;
}

.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(251, 192, 45, 0.04);
  border-color: transparent;
  color: #FBC02D;
}

.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(251, 192, 45, 0.16);
  border-color: transparent;
  color: #FBC02D;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #9C27B0;
  border: 1px solid #9C27B0;
}

.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #8c239e;
  color: #ffffff;
  border-color: #8c239e;
}

.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #df9eea;
}

.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #7d1f8d;
  color: #ffffff;
  border-color: #7d1f8d;
}

.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #9C27B0;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  color: #9C27B0;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  color: #9C27B0;
  border: 1px solid;
}

.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #9C27B0;
  border-color: transparent;
}

.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  border-color: transparent;
  color: #9C27B0;
}

.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  border-color: transparent;
  color: #9C27B0;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #D32F2F;
  border: 1px solid #D32F2F;
}

.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #c02929;
  color: #ffffff;
  border-color: #c02929;
}

.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #edacac;
}

.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #aa2424;
  color: #ffffff;
  border-color: #aa2424;
}

.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #D32F2F;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(211, 47, 47, 0.04);
  color: #D32F2F;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(211, 47, 47, 0.16);
  color: #D32F2F;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #D32F2F;
  border-color: transparent;
}

.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(211, 47, 47, 0.04);
  border-color: transparent;
  color: #D32F2F;
}

.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(211, 47, 47, 0.16);
  border-color: transparent;
  color: #D32F2F;
}

.p-button.p-button-link {
  color: #0b7ad1;
  background: transparent;
  border: transparent;
}

.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #0b7ad1;
  border-color: transparent;
}

.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}

.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: transparent;
}

.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #0b7ad1;
  border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #495057;
  color: #fff;
}

.p-speeddial-action:hover {
  background: #343a40;
  color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem 0;
}

.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem 0;
}

.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}

.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}

.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}

.p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}

.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-splitbutton {
  border-radius: 3px;
}

.p-splitbutton.p-button-outlined > .p-button {
  background-color: transparent;
  color: #2196F3;
  border: 1px solid;
}

.p-splitbutton.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(33, 150, 243, 0.04);
  color: #2196F3;
}

.p-splitbutton.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(33, 150, 243, 0.16);
  color: #2196F3;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
  color: #6c757d;
  border-color: #6c757d;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #e9ecef;
  color: #6c757d;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #dee2e6;
  color: #6c757d;
}

.p-splitbutton.p-button-text > .p-button {
  background-color: transparent;
  color: #2196F3;
  border-color: transparent;
}

.p-splitbutton.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(33, 150, 243, 0.04);
  color: #2196F3;
  border-color: transparent;
}

.p-splitbutton.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(33, 150, 243, 0.16);
  color: #2196F3;
  border-color: transparent;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button {
  color: #6c757d;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #e9ecef;
  color: #6c757d;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #dee2e6;
  color: #6c757d;
}

.p-splitbutton.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-splitbutton.p-button-rounded {
  border-radius: 2rem;
}

.p-splitbutton.p-button-rounded > .p-button {
  border-radius: 2rem;
}

.p-splitbutton.p-button-sm > .p-button {
  font-size: 0.875rem;
  padding: 0.4375rem 0.875rem;
}

.p-splitbutton.p-button-sm > .p-button .p-button-icon {
  font-size: 0.875rem;
}

.p-splitbutton.p-button-lg > .p-button {
  font-size: 1.25rem;
  padding: 0.625rem 1.25rem;
}

.p-splitbutton.p-button-lg > .p-button .p-button-icon {
  font-size: 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  background-color: transparent;
  color: #607D8B;
  border: 1px solid;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(96, 125, 139, 0.04);
  color: #607D8B;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(96, 125, 139, 0.16);
  color: #607D8B;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button {
  background-color: transparent;
  color: #607D8B;
  border-color: transparent;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(96, 125, 139, 0.04);
  border-color: transparent;
  color: #607D8B;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(96, 125, 139, 0.16);
  border-color: transparent;
  color: #607D8B;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
  background-color: transparent;
  color: #0288D1;
  border: 1px solid;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(2, 136, 209, 0.04);
  color: #0288D1;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(2, 136, 209, 0.16);
  color: #0288D1;
}

.p-splitbutton.p-button-info.p-button-text > .p-button {
  background-color: transparent;
  color: #0288D1;
  border-color: transparent;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(2, 136, 209, 0.04);
  border-color: transparent;
  color: #0288D1;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(2, 136, 209, 0.16);
  border-color: transparent;
  color: #0288D1;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
  background-color: transparent;
  color: #689F38;
  border: 1px solid;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(104, 159, 56, 0.04);
  color: #689F38;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(104, 159, 56, 0.16);
  color: #689F38;
}

.p-splitbutton.p-button-success.p-button-text > .p-button {
  background-color: transparent;
  color: #689F38;
  border-color: transparent;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(104, 159, 56, 0.04);
  border-color: transparent;
  color: #689F38;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(104, 159, 56, 0.16);
  border-color: transparent;
  color: #689F38;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
  background-color: transparent;
  color: #FBC02D;
  border: 1px solid;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(251, 192, 45, 0.04);
  color: #FBC02D;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(251, 192, 45, 0.16);
  color: #FBC02D;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button {
  background-color: transparent;
  color: #FBC02D;
  border-color: transparent;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(251, 192, 45, 0.04);
  border-color: transparent;
  color: #FBC02D;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(251, 192, 45, 0.16);
  border-color: transparent;
  color: #FBC02D;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
  background-color: transparent;
  color: #9C27B0;
  border: 1px solid;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(156, 39, 176, 0.04);
  color: #9C27B0;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(156, 39, 176, 0.16);
  color: #9C27B0;
}

.p-splitbutton.p-button-help.p-button-text > .p-button {
  background-color: transparent;
  color: #9C27B0;
  border-color: transparent;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(156, 39, 176, 0.04);
  border-color: transparent;
  color: #9C27B0;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(156, 39, 176, 0.16);
  border-color: transparent;
  color: #9C27B0;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
  background-color: transparent;
  color: #D32F2F;
  border: 1px solid;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(211, 47, 47, 0.04);
  color: #D32F2F;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(211, 47, 47, 0.16);
  color: #D32F2F;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button {
  background-color: transparent;
  color: #D32F2F;
  border-color: transparent;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(211, 47, 47, 0.04);
  border-color: transparent;
  color: #D32F2F;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(211, 47, 47, 0.16);
  border-color: transparent;
  color: #D32F2F;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-carousel .p-carousel-indicators {
  padding: 1rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #E3F2FD;
  color: #495057;
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #495057;
  background: #E3F2FD;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #2196F3;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #2196F3;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #2196F3;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #2196F3;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid #a6d5fa;
  outline-offset: 0.15rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #E3F2FD;
  color: #495057;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #E3F2FD;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #E3F2FD;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-datatable .p-column-resizer-helper {
  background: #2196F3;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-tfoot {
  background-color: #f8f9fa;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
  border-width: 0 0 0 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #E3F2FD;
  color: #495057;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #495057;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #495057;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}

.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
  padding: 1rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-menu-button:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #e9ecef;
  color: #495057;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #E3F2FD;
  color: #495057;
}

.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-clear-button:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1rem;
}

.fc {
  /* FullCalendar 4 */
  /* FullCalendar 5 */
}

.fc.fc-unthemed .fc-view-container th {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #dee2e6;
  color: #495057;
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-view-container .fc-event {
  background: #0d89ec;
  border: 1px solid #0d89ec;
  color: #ffffff;
}

.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #ffffff;
  background: #2196F3;
  border: 1px solid #2196F3;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: #0d89ec;
  color: #ffffff;
  border-color: #0d89ec;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: #0b7ad1;
  color: #ffffff;
  border-color: #0b7ad1;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #2196F3;
  border-color: #2196F3;
  color: #ffffff;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #0d89ec;
  border-color: #0d89ec;
  color: #ffffff;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  z-index: 1;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: #dee2e6;
}

.fc.fc-theme-standard .fc-view-harness th {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
}

.fc.fc-theme-standard .fc-view-harness td {
  color: #495057;
  border-color: #dee2e6;
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: #ffffff;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #f8f9fa;
  color: #495057;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "PrimeIcons" !important;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: "\e90b";
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #ffffff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: #ffffff;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: #0d89ec;
  border-color: #0d89ec;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: #e9ecef;
  color: #495057;
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #f8f9fa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: #ffffff;
  background: #2196F3;
  border: 1px solid #2196F3;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #0d89ec;
  color: #ffffff;
  border-color: #0d89ec;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: #0b7ad1;
  color: #ffffff;
  border-color: #0b7ad1;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.6;
  color: #ffffff;
  background: #2196F3;
  border: 1px solid #2196F3;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #2196F3;
  border-color: #2196F3;
  color: #ffffff;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #0d89ec;
  border-color: #0d89ec;
  color: #ffffff;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  z-index: 1;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.fc.fc-theme-standard a {
  color: #495057;
}

.fc.fc-theme-standard .fc-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}

.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}

.p-orderlist .p-orderlist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.5rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
}

.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
  background: #e9ecef;
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
  background: #e9ecef;
}

.p-orderlist-item.cdk-drag-preview {
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border: 0 none;
  color: #495057;
  background: #ffffff;
  margin: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #E3F2FD;
  color: #495057;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #6cbbf5;
}

.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #dee2e6;
}

.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 2.357rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 2.357rem;
}

.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #E3F2FD;
  border-color: #E3F2FD;
  color: #495057;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}

.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}

.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.5rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}

.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
}

.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
  background: #e9ecef;
}

.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
  background: #e9ecef;
}

.p-picklist-item.cdk-drag-preview {
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border: 0 none;
  color: #495057;
  background: #ffffff;
  margin: 0;
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid #2196F3;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
}

.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
  border-radius: 3px;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 3px;
  transition: box-shadow 0.2s;
  padding: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #6c757d;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #E3F2FD;
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ecef;
  color: #495057;
}

.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #89c8f7;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 3px;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  color: #495057;
  padding: 0;
  transition: box-shadow 0.2s;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #E3F2FD;
  color: #495057;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #495057;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}

.p-treetable .p-sortable-column {
  outline-color: #a6d5fa;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #495057;
  background: #E3F2FD;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}

.p-treetable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #2196F3;
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #2196F3;
}

.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}

.p-treetable .p-treetable-tbody > tr:focus {
  outline: 0.15rem solid #a6d5fa;
  outline-offset: -0.15rem;
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #E3F2FD;
  color: #495057;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #495057;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #495057;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #495057;
}

.p-treetable .p-column-resizer-helper {
  background: #2196F3;
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}

.p-virtualscroller .p-virtualscroller-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}

.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.p-card .p-card-body {
  padding: 1rem;
}

.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}

.p-card .p-card-content {
  padding: 1rem 0;
}

.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: #ffffff;
}

.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px #dee2e6;
}

.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}

.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}

.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}

.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-radius: 3px;
}

.p-fieldset .p-fieldset-legend {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 3px;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem;
  color: #495057;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}

.p-fieldset .p-fieldset-content {
  padding: 1rem;
}

.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #f8f9fa;
  color: #495057;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}

.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}

.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 0 none;
}

.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0 none;
}

.p-panel .p-panel-icons-end {
  order: 2;
  margin-left: auto;
}

.p-panel .p-panel-icons-start {
  order: 0;
  margin-right: 0.5rem;
}

.p-panel .p-panel-icons-center {
  order: 2;
  width: 100%;
  text-align: center;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #dee2e6;
  background: #ffffff;
  border-radius: 3px;
  color: #495057;
}

.p-splitter .p-splitter-gutter {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background: #f8f9fa;
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #dee2e6;
}

.p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #a6d5fa;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #6c757d;
  color: #6c757d;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #2196F3;
  color: #2196F3;
}

.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}

.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff;
  color: #2196F3;
  width: 2.357rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #a6d5fa;
}

.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1rem;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-radius: 3px;
  gap: 0.5rem;
}

.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-confirm-popup .p-confirm-popup-content {
  padding: 1rem;
}

.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.5rem 1rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}

.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #ffffff;
}

.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 1.5rem;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}

.p-overlaypanel .p-overlaypanel-close {
  background: #2196F3;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #0d89ec;
  color: #ffffff;
}

.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #f2f2f2;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.p-sidebar {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-sidebar .p-sidebar-header {
  padding: 1rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}

.p-sidebar .p-sidebar-content {
  padding: 1rem;
}

.p-sidebar .p-sidebar-footer {
  padding: 1rem;
}

.p-tooltip .p-tooltip-text {
  background: #495057;
  color: #ffffff;
  padding: 0.5rem 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-fileupload .p-progressbar {
  height: 0.25rem;
}

.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #0d89ec;
  color: #ffffff;
  border-color: #0d89ec;
}

.p-fileupload-choose:not(.p-disabled):active {
  background: #0b7ad1;
  color: #ffffff;
  border-color: #0b7ad1;
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 1rem;
}

.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.2s;
  border-radius: 3px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #495057;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #495057;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.p-contextmenu .p-menuitem {
  margin: 0;
}

.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-contextmenu .p-menuitem-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}

.p-dock .p-dock-item {
  padding: 0.5rem;
}

.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}

.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}

.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}

.p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}

.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}

.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}

.p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 3px;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 3px;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}

.p-megamenu .p-menuitem-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-menu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  width: 12.5rem;
}

.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-menu .p-menuitem {
  margin: 0;
}

.p-menu .p-menuitem:last-child {
  margin: 0;
}

.p-menu .p-menuitem-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 3px;
}

.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 3px;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}

.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}

.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}

.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-menuitem-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #6c757d;
    background: #e9ecef;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #6c757d;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #a6d5fa;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-panelmenu .p-menuitem-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  width: 12.5rem;
}

.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #495057;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #6c757d;
}

.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}

.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #495057;
}

.p-slidemenu .p-menuitem-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 3px;
  background: #ffffff;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  border: 1px solid #e9ecef;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #E3F2FD;
  color: #495057;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #495057;
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}

.p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #a6d5fa;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #6c757d;
  color: #6c757d;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #2196F3;
  color: #2196F3;
}

.p-tabmenu .p-tabmenu-left-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-right-icon {
  margin-left: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link {
  background: #ffffff;
  color: #2196F3;
  width: 2.357rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #a6d5fa;
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-tieredmenu .p-menuitem {
  margin: 0;
}

.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-tieredmenu .p-menuitem-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-inline-message {
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-radius: 3px;
}

.p-inline-message.p-inline-message-info {
  background: #B3E5FC;
  border: solid #0891cf;
  border-width: 0px;
  color: #044868;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #044868;
}

.p-inline-message.p-inline-message-success {
  background: #C8E6C9;
  border: solid #439446;
  border-width: 0px;
  color: #224a23;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #224a23;
}

.p-inline-message.p-inline-message-warn {
  background: #FFECB3;
  border: solid #d9a300;
  border-width: 0px;
  color: #6d5100;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #6d5100;
}

.p-inline-message.p-inline-message-error {
  background: #FFCDD2;
  border: solid #e60017;
  border-width: 0px;
  color: #73000c;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #73000c;
}

.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 3px;
}

.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}

.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-message.p-message-info {
  background: #B3E5FC;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
}

.p-message.p-message-info .p-message-icon {
  color: #044868;
}

.p-message.p-message-info .p-message-close {
  color: #044868;
}

.p-message.p-message-success {
  background: #C8E6C9;
  border: solid #439446;
  border-width: 0 0 0 6px;
  color: #224a23;
}

.p-message.p-message-success .p-message-icon {
  color: #224a23;
}

.p-message.p-message-success .p-message-close {
  color: #224a23;
}

.p-message.p-message-warn {
  background: #FFECB3;
  border: solid #d9a300;
  border-width: 0 0 0 6px;
  color: #6d5100;
}

.p-message.p-message-warn .p-message-icon {
  color: #6d5100;
}

.p-message.p-message-warn .p-message-close {
  color: #6d5100;
}

.p-message.p-message-error {
  background: #FFCDD2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
}

.p-message.p-message-error .p-message-icon {
  color: #73000c;
}

.p-message.p-message-error .p-message-close {
  color: #73000c;
}

.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}

.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.p-message .p-message-summary {
  font-weight: 700;
}

.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}

.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #B3E5FC;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #044868;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: #C8E6C9;
  border: solid #439446;
  border-width: 0 0 0 6px;
  color: #224a23;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #224a23;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #FFECB3;
  border: solid #d9a300;
  border-width: 0 0 0 6px;
  color: #6d5100;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #6d5100;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #FFCDD2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #73000c;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}

.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}

.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  margin: 0 0.5rem;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}

.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #f8f9fa;
  padding: 1rem;
}

.p-galleria .p-galleria-indicators {
  padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #E3F2FD;
  color: #495057;
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #E3F2FD;
  color: #495057;
}

.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #f8f9fa;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-image-action.p-link:last-child {
  margin-right: 0;
}

.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}

.p-image-action.p-link i {
  font-size: 1.5rem;
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: 3px;
}

.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-badge {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
  background-color: #607D8B;
  color: #ffffff;
}

.p-badge.p-badge-success {
  background-color: #689F38;
  color: #ffffff;
}

.p-badge.p-badge-info {
  background-color: #0288D1;
  color: #ffffff;
}

.p-badge.p-badge-warning {
  background-color: #FBC02D;
  color: #212529;
}

.p-badge.p-badge-danger {
  background-color: #D32F2F;
  color: #ffffff;
}

.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-chip {
  background-color: #dee2e6;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.5rem;
}

.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}

.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.5rem;
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-inplace .p-inplace-display {
  padding: 0.5rem 0.5rem;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #495057;
}

.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 3px;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #2196F3;
}

.p-progressbar .p-progressbar-label {
  color: #ffffff;
  line-height: 1.5rem;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}

.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}

.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #f8f9fa;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: 3px;
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #2196F3;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
}

.p-tag.p-tag-success {
  background-color: #689F38;
  color: #ffffff;
}

.p-tag.p-tag-info {
  background-color: #0288D1;
  color: #ffffff;
}

.p-tag.p-tag-warning {
  background-color: #FBC02D;
  color: #212529;
}

.p-tag.p-tag-danger {
  background-color: #D32F2F;
  color: #ffffff;
}

.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-terminal {
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
}

.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Customizations to the designer theme should be defined here */
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #2196F3;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background-color: #2196F3;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #2196F3;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #2196F3;
}