body {
  font-family: var(--font-family-open-sans);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-line-height-19);
  letter-spacing: var(--font-letter-spacing-025);
  color: var(--color-black);

  /* (Opcional) */
  hyphens: auto;
  /* (Opcional) */
  font-smooth: always;
  /* (Opcional) */
  -webkit-font-smoothing: antialiased;
  /* (Opcional) */
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-0);
  line-height: var(--font-line-height-33);
}

h2 {
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-017);
  line-height: var(--font-line-height-28);
}

h3 {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-014);
  line-height: var(--font-line-height-24);
}

h4 {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-0);
  line-height: var(--font-line-height-22);
}

h5 {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--font-letter-spacing-009);
  line-height: var(--font-line-height-19);
}

p {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--font-letter-spacing-025);
  line-height: var(--font-line-height-19);
}

a {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--font-letter-spacing-09);
  line-height: var(--font-line-height-19);
  color: var(--color-primary5);
}

strong {
  font-weight: var(--font-weight-bold);
}
