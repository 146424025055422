/* Scrollbar: Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-grey8);
  border-radius: 20px;
  width: 3px;

  &:hover {
    background: var(--color-grey9);
  }
}

/* Scrollbar: Firefox */
* {
  // scrollbar-width: thin;
  scrollbar-color: var(--color-grey8);
}
