::ng-deep.p-datepicker {
  border: none;
  padding: 0;
  border-radius: 8px;
}

::ng-deep.p-datepicker .p-datepicker-header {
  border: none;
}

::ng-deep.p-datepicker .p-datepicker-header .p-link {
  display: inline-flex;
  cursor: default;
}

::ng-deep.p-datepicker .p-datepicker-group {
  border: none !important;
}

::ng-deep.p-datepicker-group-container {
  border: none;
  padding: 0;
  padding-bottom: 0;
}

::ng-deep.p-datepicker table td > span {
  cursor: default;
}

::ng-deep.p-datepicker table td.p-datepicker-today > span {
  background-color: transparent;
  border: 1px solid var(--color-primary5);
  color: var(--color-black);
}

::ng-deep.p-datepicker table td > span.p-highlight {
  color: var(--color-black);
  background: #e3f2fd;
  background-color: var(--color-secondary8);
}
