::ng-deep .p-overlaypanel {
  box-shadow: none;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }

  .p-overlaypanel-content {
    padding: 0;
  }
}
