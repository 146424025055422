@use 'sass:map';
@use 'abstracts/utils';

// Variables
$breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px
);

// Helper functions
@function screen-size($breakpoint) {
  @return map.get($breakpoints, $breakpoint);
}

@function next-screen-size($breakpoint) {
  @return utils.map-get-next($breakpoints, $breakpoint);
}

@function previous-screen-size($breakpoint) {
  @return utils.map-get-previous($breakpoints, $breakpoint);
}

@function next-breakpoint($breakpoint) {
  @return utils.map-get-next($breakpoints, $breakpoint, key);
}

@function previous-breakpoint($breakpoint) {
  @return utils.map-get-previous($breakpoints, $breakpoint, key);
}

// Information

/// * Mobile: 0 -> 639.999px
/// * Small Tablet: 640px -> 767.999px
/// * Tablet: 768px -> 1023.999px
/// * Laptop: 1024px -> 1279.999px
/// * Desktop: 1280px -> 1535.999px
/// * Big screen: 1536px -> Infinity

/** @deprecated */
$mobile: 599px;
$tablet-min: 600px;
$tablet-max: 1239px;
$desktop-min: 1240px;
$desktop-max: 1440px;

// CSS ROOT VARIABLES
:root {
  /**************/
  /*   TEXTOS   */
  /**************/
  //font family
  --font-family-open-sans: Open sans, sans-serif;

  // font sizes
  --font-size-24: 24px;
  --font-size-22: 22px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --font-size-12: 12px;

  // font weights
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-normal: 400;

  // line heights
  --font-line-height-33: 33px;
  --font-line-height-28: 28px;
  --font-line-height-24: 24px;
  --font-line-height-22: 22px;
  --font-line-height-19: 19px;
  --font-line-height-17: 17px;

  // letter spacing
  --font-letter-spacing-09: 0.9px;
  --font-letter-spacing-04: 0.4px;
  --font-letter-spacing-025: 0.25px;
  --font-letter-spacing-017: 0.17px;
  --font-letter-spacing-014: 0.14px;
  --font-letter-spacing-009: 0.09;
  --font-letter-spacing-0: 0;

  /* ***************** */
  /* ***   COLORS  *** */
  /* ***************** */
  /* ***   Primary  *** */
  --color-primary: #004ab7;
  --color-primary2: #00257a;
  --color-primary3: #006fff;
  --color-primary4: #006ff540;
  --color-primary5: #006ff5;
  --color-primary6: #0082ff;
  --color-primary7: #28344a;
  --color-primary8: rgb(71, 215, 172);
  --color-primary9: #b1d4f6;
  --color-primary10: #eff7ff;
  --color-primary11: rgba(0, 130, 255, 0.1);
  --color-primary12: #e5edff;
  --color-primary13: #f6f9ff;
  --color-primary14: #f6fafc;
  --color-primary15: #cedee7;
  --color-primary16: #f2f9ff;

  /* ***   Black and grey  *** */
  --color-white: #fff;
  --color-grey: #ebebeb;
  --color-grey2: #707070;
  --color-grey3: #e5ecf7;
  --color-grey4: #ececec;
  --color-grey5: #dfdfdf;
  --color-grey6: #f1f1f1;
  --color-grey7: #f2f2f2;
  --color-grey8: #d8d8d8;
  --color-grey9: #dcdcdc;
  --color-grey10: #a6a6a6;
  --color-grey11: #979797;
  --color-grey12: #f5f5f5;
  --color-grey13: #eae9e9;
  --color-grey14: #f7f7f7;
  --color-grey15: #7a7a7a;
  --color-grey16: #fafafa;
  --color-grey17: #c4c2c2;
  --color-black: #333333;
  --color-black2: #505050;
  --color-black3: #28344a;
  --color-black4: #666666;
  --color-black5: #555555;
  --color-black6: #000000;

  /* ***   secondary  *** */
  --color-secondary: #2d373d;
  --color-secondary2: #526570;
  --color-secondary3: #9cb0bc;
  --color-secondary4: #e8ebed;
  --color-secondary5: #f5f6f7;
  --color-secondary6: #89969a;
  --color-secondary7: #f1f8ff;
  --color-secondary8: #ccf0ff;
  --color-secondary9: #d2e8fd;
  --color-selected: #f8efcd;

  /* *** type style *** */
  --color-type: #ccf0ff;
  --color-type2: #f9ecde;
  --color-type3: #e6fbd5;
  --color-type4: #ffe6d8;
  --color-type5: #47d7ac;
  --color-type6: #0082ff;
  --color-type7: #b50500;
  --color-type8: #fbf5ed;
  --color-type9: #f9ecde;
  --color-type10: #fef9f2;
  --color-type11: #bd4412;
  --color-type12: #006700;
  --color-type13: #f6d6d0;

  /* *** Measures *** */
  --sidebar-width: 248px;
}
